$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

body {
    background-color: #ededed;

    header {
        img {
            max-width: 150px;
            @media (min-width: $lg) {
                max-width: 200px;
            }
        }

    }

    .splide__slide {
        margin-right: .75rem;

        @media (min-width: $sm) {
            margin-right: 2rem;
        }
    }

    .splide__track {
        z-index: 1;
    }

    .splide__pagination {
        background-color: #d4d4d4;
        z-index: 0;
        width: 100%;
        height: 85%;
        align-items: end;
        bottom: -15px;

        .splide__pagination__page {
            transform: scale(.7) !important;
            background: #949494;

            @media (min-width: $sm) {
                display: none;
            }

            &:hover {
                cursor: pointer;
                background: #4e6956;
            }

            &.is-active {
                background: #435A4A;
            }
        }

        @media (min-width: $sm) {
            bottom: -25px;
        }
    }

    .splide__arrow {
        z-index: -1;

        @media (min-width: $sm) {
            z-index: 2;
        }

        svg {
            fill: #b0b0b3;

            &:hover {
                fill: #435A4A;
            }
        }

        &.splide__arrow--prev {
            @media (min-width: $sm) {
                left: 7em;
            }
        }

        &.splide__arrow--next {
            @media (min-width: $sm) {
                right: 7em;
            }
        }
    }


    main {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 400;
        color: #464647;

        .texto {

            p {
                margin: 3rem 0 !important;
                max-width: 900px !important;
                @media (min-width: $lg) {
                    font-size: 24px;
                }
            }

            @media (min-width: $sm) {
                p {
                    margin: 4rem 0 !important;
                }
            }
            @media (min-width: $md) { }
            @media (min-width: $lg) { }
            @media (min-width: $xl) {
                p {
                    margin: 4rem 0 !important;
                }
            }
            @media (min-width: $xxl) {
                p {
                    margin: 5rem 0 !important;
                }
            }

        }

        .content {

            h2 {
                font-size: 24px;
                font-weight: 700;
                display: none;
            }
        }

        .btn-checkout {
            color: #000;
            background-color: #d4d4d4;
            border-color: #d4d4d4;
            font-size: 24px;
            font-weight: 700;
            text-transform: uppercase;

            &:hover {
                color: #d4d4d4;
                background-color: #000;
                border-color: #000;
            }
        }

        .garrafa {

            img {
                max-width: 100%;
            }

        }

        .social {
            padding: 50px 0;
            background-color: #d4d4d4;
            max-width: 2016px;
            margin: 0 auto 50px;

            a {
                color: #464647;
                font-size: 30px;

                &:hover {
                    color: #435A4A;
                }
            }

            span {
                font-size: 12px;
                display: block;
            }

            @media (min-width: $md) {
                a {
                    font-size: 40px;
                }

                span {
                    font-size: 12px;
                }
            }
        }
    }
}
