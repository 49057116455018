.overflow_hidden {
    overflow: hidden;
}

//Loading
#loading {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: fixed;
	background: rgba(0,0,0,.5);
	z-index: 999999;
	// display: none;

    .spinner-border,
	i {
		color: #0d6efd;
		position: fixed;
		top: 50%;
		left: 50%;
		margin: -13px 0 0 -13px;
		font-size: 30px;
	}
} //loading
